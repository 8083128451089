import React, { useState } from 'react';
import "../base.css";
import "../assets/about.css";
import AboutImg from "../assets/img/about.jpg";
import CV from "../assets/files/Aylin-CV.pdf";
import CoffeeCounter from './CoffeeCounter';

function About() {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="about starter-margin">
            <div class="container">
                <div className="row" id="lead-section">

                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <img src={AboutImg} alt="Aylin" className="about__img" />
                    </div>

                    <div className="col-lg-8 col-md-12 col-sm-12 about__info">

                        <div className="about__data row">
                            <div className="about__box col">
                                <i class="bx bx-award about__icon"></i>

                                <h3 className="about__title">Experience</h3>
                                <span className="about__subtitle">10 years</span>
                            </div>

                            <div className="about__box col">
                                <i class="bx bx-briefcase-alt about__icon"></i>

                                <h3 className="about__title">Completed</h3>
                                <span className="about__subtitle">13+ projects</span>
                            </div>

                            <CoffeeCounter />

                            <div className="about__box col">
                                <i class="bx bxs-cat about__icon"></i>

                                <h3 className="about__title">Cat</h3>
                                <span className="about__subtitle">2</span>
                            </div>
                        </div>

                        <p className="about__description">
                        As a User Interface (UI) Developer with 10 years of experience, specializing in Agile methodology, branding, and User Experience (UX) Design, I have a strong history of working with diverse technical teams to create scalable code and web, and mobile solutions. I am adept at managing stakeholder expectations throughout all phases of the project lifecycle, striving for excellence in every UI development endeavor.
                        </p>

                        <a download="../assets/files/Aylin-CV.pdf" href={CV} className="button button--flex">
                            Download CV 
                            <svg
                                class="button__icon"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M15.25 22.7502H9.25C3.82 22.7502 1.5 20.4302 1.5 15.0002V9.00024C1.5 3.57024 3.82 1.25024 9.25 1.25024H14.25C14.66 1.25024 15 1.59024 15 2.00024C15 2.41024 14.66 2.75024 14.25 2.75024H9.25C4.64 2.75024 3 4.39024 3 9.00024V15.0002C3 19.6102 4.64 21.2502 9.25 21.2502H15.25C19.86 21.2502 21.5 19.6102 21.5 15.0002V10.0002C21.5 9.59024 21.84 9.25024 22.25 9.25024C22.66 9.25024 23 9.59024 23 10.0002V15.0002C23 20.4302 20.68 22.7502 15.25 22.7502Z"
                                    fill="var(--container-color)"
                                ></path>
                                <path
                                    d="M22.25 10.7502H18.25C14.83 10.7502 13.5 9.42023 13.5 6.00023V2.00023C13.5 1.70023 13.68 1.42023 13.96 1.31023C14.24 1.19023 14.56 1.26023 14.78 1.47023L22.78 9.47023C22.99 9.68023 23.06 10.0102 22.94 10.2902C22.82 10.5702 22.55 10.7502 22.25 10.7502ZM15 3.81023V6.00023C15 8.58023 15.67 9.25023 18.25 9.25023H20.44L15 3.81023Z"
                                    fill="var(--container-color)"
                                ></path>
                                <path
                                    d="M13.25 13.7502H7.25C6.84 13.7502 6.5 13.4102 6.5 13.0002C6.5 12.5902 6.84 12.2502 7.25 12.2502H13.25C13.66 12.2502 14 12.5902 14 13.0002C14 13.4102 13.66 13.7502 13.25 13.7502Z"
                                    fill="var(--container-color)"
                                ></path>
                                <path
                                    d="M11.25 17.7502H7.25C6.84 17.7502 6.5 17.4102 6.5 17.0002C6.5 16.5902 6.84 16.2502 7.25 16.2502H11.25C11.66 16.2502 12 16.5902 12 17.0002C12 17.4102 11.66 17.7502 11.25 17.7502Z"
                                    fill="var(--container-color)"
                                ></path>
                            </svg>
                        </a>

                    </div>

                </div>

                <hr></hr>

                <div className="row" id="stack-section">
                    <div className="col">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="stack__data row">
                                <div className="stack__box col">
                                    <img src="/badges/html.svg" alt="HTML Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/css.svg" alt="CSS Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/javascript.svg" alt="JavaScript Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/typescript.svg" alt="TypeScript Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/react-js.svg" alt="React.js Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/next-js.svg" alt="Next.js Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/vue-js.svg" alt="Vue.js Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/angular-js.svg" alt="Angular.js Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/tailwindcss.svg" alt="TailwindCSS Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/bootstrap.svg" alt="Bootstrap Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/material-ui.svg" alt="Material UI Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/ant-design.svg" alt="Ant Design Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/node-js.svg" alt="Node.js Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/express-js.svg" alt="Express.js Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/mongodb.svg" alt="MongoDB Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/firebase.svg" alt="Firebase Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/vercel.svg" alt="Vercel Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/netlify.svg" alt="Netlify Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/mendix.svg" alt="Mendix Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/photoshop.svg" alt="Photoshop Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/adobe-xd.svg" alt="Adobe XD Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/figma.svg" alt="Figma Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/sanity.svg" alt="Sanity Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/design-principles.svg" alt="DP Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/design-thinking.svg" alt="DT Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/branding.svg" alt="Branding Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/ui_ux.svg" alt="UI/UX Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/illustrator.svg" alt="Illustrator Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/docker.svg" alt="Docker Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/ci_cd.svg" alt="CI/CD Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/git.svg" alt="Git Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/quality.svg" alt="Quality Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/agile.svg" alt="Agile Badge" className="stack-logo" />
                                </div>
                                <div className="stack__box col">
                                    <img src="/badges/jira.svg" alt="Jira Badge" className="stack-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr></hr>

                <div className="row" id="qualification-section">
                    <div className="qualification__container container">
                        <div className="qualification__tabs">
                            <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(1)}>
                                <i className="uil uil-graduation-cap qualification__icon"></i> Education
                            </div>

                            <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(2)}>
                                <i className="uil uil-briefcase-alt qualification__icon"></i> Certification
                            </div>
                        </div>

                        <div className="qualification__sections">
                            <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                                <div className="qualification__data">
                                    <div>
                                        <h3 className="qualification__title">Software Engineering</h3>
                                        <span className="qualification__subtitle">Netkent Mediterranean Study and Science University, Cyprus</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2020 - Present
                                        </div>
                                    </div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div></div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>

                                    <div>
                                        <h3 className="qualification__title">Graphic Design</h3>
                                        <span className="qualification__subtitle">Bilecik Seyh Edebali University, TR</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2008 - 2010
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                                <div className="qualification__data">
                                    <div></div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>

                                    <div>
                                        <h3 className="qualification__title">Mendix: Low-code Application Development</h3>
                                        <span className="qualification__subtitle">Udemy</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2023
                                        </div>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div>
                                        <h3 className="qualification__title">The MERN Fullstack</h3>
                                        <span className="qualification__subtitle">Udemy</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2023
                                        </div>
                                    </div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div></div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>

                                    <div>
                                        <h3 className="qualification__title">Agile User Experience Design and Research</h3>
                                        <span className="qualification__subtitle">LinkedIn Learning</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2022
                                        </div>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div>
                                        <h3 className="qualification__title">The 12 Principles Used in UI</h3>
                                        <span className="qualification__subtitle">LinkedIn Learning</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2022
                                        </div>
                                    </div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div></div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>

                                    <div>
                                        <h3 className="qualification__title">Complete Applied Web Development</h3>
                                        <span className="qualification__subtitle">Udemy</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2020
                                        </div>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div>
                                        <h3 className="qualification__title">User Experience Design</h3>
                                        <span className="qualification__subtitle">Udemy</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2017
                                        </div>
                                    </div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>
                                </div>

                                <div className="qualification__data">
                                    <div></div>

                                    <div>
                                        <span className="qualification__rounder"></span>
                                        <span className="qualification__line"></span>
                                    </div>

                                    <div>
                                        <h3 className="qualification__title">HTML, CSS, Js, Bootstrap</h3>
                                        <span className="qualification__subtitle">W3schools.com</span>
                                        <div className="qualification__calendar">
                                            <i className="uil uil-calendar-alt"></i> 2012
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
        </div>
    );
}

export default About;
import React from 'react';

function Footer() {
    return (
        <div className="footer">
            <footer class="footer-type fixed-bottom">
                <div class="container-fluid">
                    <p class="m-3 text-center">
                        <i class="bx bx-coffee-togo"></i> Flat White. No sugar please.
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
import React, { useState } from 'react';
import "../base.css";
import "../assets/portfolio.css";
import { Link } from 'react-router-dom';
/* THUMBS */
import Branding1 from "../portfolio/branding/work1.jpg";
import Branding2 from "../portfolio/branding/work2.jpg";
import Branding3 from "../portfolio/branding/work3.jpg";
import Branding4 from "../portfolio/branding/work4.jpg";
import Branding5 from "../portfolio/branding/work5.jpg";
import Branding7 from "../portfolio/branding/work7.jpg";
import Branding12 from "../portfolio/branding/work12.jpg";
import Ltr1 from "../portfolio/branding/work.jpg";
import Aembit1 from "../portfolio/branding/work13.jpg";
/* WORK GALLERY */
/* LIT */
import Aembit6 from "../portfolio/branding/aembit/00.jpg";
import Aembit2 from "../portfolio/branding/aembit/01.jpg";
import Aembit3 from "../portfolio/branding/aembit/02.jpg";
import Aembit4 from "../portfolio/branding/aembit/03.jpg";
import Aembit5 from "../portfolio/branding/aembit/04.jpg";
/* LIT */
import Ltr2 from "../portfolio/branding/lit/01.jpg";
import Ltr3 from "../portfolio/branding/lit/02.jpg";
import Ltr4 from "../portfolio/branding/lit/04.jpg";
import Ltr5 from "../portfolio/branding/lit/05.jpg";
/* Sodek */
import Sodek1 from "../portfolio/branding/sodek/01.jpg";
import Sodek2 from "../portfolio/branding/sodek/02.jpg";
import Sodek3 from "../portfolio/branding/sodek/03.jpg";
import Sodek4 from "../portfolio/branding/sodek/04.jpg";
import Sodek5 from "../portfolio/branding/sodek/05.jpg";
import Sodek6 from "../portfolio/branding/sodek/06.jpg";
import Sodek7 from "../portfolio/branding/sodek/07.jpg";
import Sodek8 from "../portfolio/branding/sodek/08.jpg";
import Sodek9 from "../portfolio/branding/sodek/09.jpg";
import Sodek10 from "../portfolio/branding/sodek/10.jpg";
import Sodek11 from "../portfolio/branding/sodek/11.jpg";
import Sodek12 from "../portfolio/branding/sodek/12.jpg";
import Sodek13 from "../portfolio/branding/sodek/13.jpg";
import Sodek14 from "../portfolio/branding/sodek/14.jpg";
import Sodek15 from "../portfolio/branding/sodek/15.jpg";
/* Gunseven */
import Gunseven1 from "../portfolio/branding/gunseven/01.jpg";
import Gunseven2 from "../portfolio/branding/gunseven/02.jpg";
import Gunseven3 from "../portfolio/branding/gunseven/03.jpg";
import Gunseven4 from "../portfolio/branding/gunseven/04.jpg";
import Gunseven5 from "../portfolio/branding/gunseven/05.jpg";
import Gunseven6 from "../portfolio/branding/gunseven/06.jpg";
import Gunseven7 from "../portfolio/branding/gunseven/07.jpg";
import Gunseven8 from "../portfolio/branding/gunseven/08.jpg";
import Gunseven9 from "../portfolio/branding/gunseven/09.jpg";
import Gunseven10 from "../portfolio/branding/gunseven/10.jpg";
import Gunseven11 from "../portfolio/branding/gunseven/11.jpg";
import Gunseven12 from "../portfolio/branding/gunseven/12.jpg";
import Gunseven13 from "../portfolio/branding/gunseven/13.jpg";
import Gunseven14 from "../portfolio/branding/gunseven/14.jpg";
import Gunseven15 from "../portfolio/branding/gunseven/15.jpg";
import Gunseven16 from "../portfolio/branding/gunseven/16.jpg";
import Gunseven17 from "../portfolio/branding/gunseven/17.jpg";
import Gunseven18 from "../portfolio/branding/gunseven/18.jpg";
import Gunseven19 from "../portfolio/branding/gunseven/19.jpg";
import Gunseven20 from "../portfolio/branding/gunseven/20.jpg";
/* Garage */
import Garage1 from "../portfolio/branding/work3.jpg";
/* Sandraport */
import Sandraport1 from "../portfolio/branding/sandraport/01.jpg";
import Sandraport2 from "../portfolio/branding/sandraport/02.jpg";
import Sandraport3 from "../portfolio/branding/sandraport/03.jpg";
import Sandraport4 from "../portfolio/branding/sandraport/04.jpg";
import Sandraport5 from "../portfolio/branding/sandraport/05.jpg";
/* Thingo */
import Thingo1 from "../portfolio/branding/work5.jpg";
/* Gaea */
import Gaea1 from "../portfolio/branding/work7.jpg";
/* BMHCOMP */
import Bmh1 from "../portfolio/branding/work12.jpg";

function Branding() {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="branding starter-margin">
            <div class="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="portfolio__title">Selected Works</h3>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <p className="note confidential-note">For privacy reasons, I am unable to share visuals, names, and resources of specific projects.</p>
                    </div>
                    
                    <div className="tabset">
                        <ul>
                            <li className="tab__item">
                                <Link className="tab__item-link" to="/portfolio">UI Development</Link>
                            </li>
                            <li className="tab__item">
                                <Link className="tab__item-link" to="/uxuidesign">UX/UI Design</Link>
                            </li>
                            <li className="tab__item active">
                                <Link className="tab__item-link" to="/branding">Branding</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row work__grid">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Aembit1} alt="work" title="Aembit" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(1)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 1 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Aembit</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe Photoshop
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://aembit.io/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, color palette, typography, branding guide<br>
                                            </br><br></br><strong>Period:</strong> 2 months
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Aembit1} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit6} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit2} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit3} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit4} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit5} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Ltr1} alt="work" title="Lives in Turkish" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(2)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 2 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Lives in Turkish</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://www.livesinturkish.com/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, color palette, typography<br>
                                            </br><br></br><strong>Period:</strong> 1 month
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Ltr1} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Ltr2} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Ltr3} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Ltr4} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Ltr5} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Branding1} alt="work" title="Sodek" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(3)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 3 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Sodek</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe InDesign, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://www.sodek.com.tr/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, letterhead, envelope, pocket folder, catalogue, brand style guide, cd and cd cover, branded tshirt<br>
                                            </br><br></br><strong>Period:</strong> 6 months
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Sodek1} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek2} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek3} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek4} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek5} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek6} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek7} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek8} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek9} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek10} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek11} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek12} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek13} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek14} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sodek15} alt="work" title="Sodek" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Branding2} alt="work" title="Gunseven" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(4)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 4 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Gunseven</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe InDesign, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://www.gunsevensalgam.com.tr/tr" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, envelope, catalogue, pocket folder, cd, letterhead, pencil, wallpapers, outdoor ads, pen, eraser, branded paper clip, branded usb, notepads, branded tshirt, packaging<br>
                                            </br><br></br><strong>Period:</strong> 10 months
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Gunseven1} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven2} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven3} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven4} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven5} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven6} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven7} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven8} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven9} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven10} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven11} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven12} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven13} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven14} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven15} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven16} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven17} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven18} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven19} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Gunseven20} alt="work" title="Gunseven" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Branding3} alt="work" title="Garage" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(5)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 5 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Garage</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, letterhead, envelope, corporate paperwork, pocket folder, pencil, branded paper clip<br>
                                            </br><br></br><strong>Period:</strong> 2 weeks
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Garage1} alt="work" title="Garage" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Branding4} alt="work" title="Sandraport" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(6)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 6 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Sandraport</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, envelope, letterhead, notepads, pen, pencil, eraser, wallpapers, branded usb, branded paper clip<br>
                                            </br><br></br><strong>Period:</strong> 5 months
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Sandraport1} alt="work" title="Sandraport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sandraport2} alt="work" title="Sandraport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sandraport3} alt="work" title="Sandraport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sandraport4} alt="work" title="Sandraport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Sandraport5} alt="work" title="Sandraport" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Branding5} alt="work" title="Thingo" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(7)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 7 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Thingo</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, letterhead, cd and cd-cover, seal, branded bag, branded ruler, pencil, eraser, embroidery kit, label<br>
                                            </br><br></br><strong>Period:</strong> 1 month
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Thingo1} alt="work" title="Thingo" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Branding7} alt="work" title="Gaea" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(8)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 8 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Gaea</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, packaging<br>
                                            </br><br></br><strong>Period:</strong> 1 month
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Gaea1} alt="work" title="Gaea" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Branding12} alt="work" title="BMHCOMP" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(9)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 9 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">BMHCOMP</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator, Adobe Photoshop, Adobe Dimension
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="http://bmhcomp.com/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <p className="work__modal-text note">
                                            <strong>Package:</strong> Logo, business card, letterhead, envelope, notepads, seal, pen, map case, desk plaque, branded ruler<br>
                                            </br><br></br><strong>Period:</strong> 2 months
                                        </p>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Bmh1} alt="work" title="BMHCOMP" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
        </div>
    );
}

export default Branding;
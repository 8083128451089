import React, { useState, useEffect } from 'react';

const CoffeeCounter = () => {
  const [coffeeCount, setCoffeeCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCoffeeCount(prevCount => prevCount + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="about__box col">
      <i className="bx bx-coffee about__icon"></i>
      <h3 className="about__title">Coffee</h3>
      <span className="about__subtitle">{coffeeCount}</span>
    </div>
  );
};

export default CoffeeCounter;
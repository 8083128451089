import React, { useState } from 'react';
import "../base.css";
import "../assets/portfolio.css";
import { Link } from 'react-router-dom';
/* THUMBS */
import Uxui1 from "../portfolio/uxui/work1.jpg";
import Uxui2 from "../portfolio/uxui/work2.jpg";
import Uxui3 from "../portfolio/uxui/work3.jpg";
import Uxui4 from "../portfolio/uxui/work4.jpg";
import Uxui5 from "../portfolio/uxui/work5.jpg";
import Uxui6 from "../portfolio/uxui/work6.jpg";
/* WORK GALLERY */
/* Erasmusport */
import Erasmusport1 from "../portfolio/uxui/erasmusport/01.jpg";
import Erasmusport2 from "../portfolio/uxui/erasmusport/02.jpg";
import Erasmusport3 from "../portfolio/uxui/erasmusport/03.jpg";
import Erasmusport4 from "../portfolio/uxui/erasmusport/04.jpg";
import Erasmusport5 from "../portfolio/uxui/erasmusport/05.jpg";
/* Nerede Ne Yenir */
import NNYenir1 from "../portfolio/uxui/nerede-ne-yenir/01.jpg";
import NNYenir2 from "../portfolio/uxui/nerede-ne-yenir/02.jpg";
import NNYenir3 from "../portfolio/uxui/nerede-ne-yenir/03.jpg";
import NNYenir4 from "../portfolio/uxui/nerede-ne-yenir/04.jpg";
import NNYenir5 from "../portfolio/uxui/nerede-ne-yenir/05.jpg";
import NNYenir6 from "../portfolio/uxui/nerede-ne-yenir/06.jpg";
import NNYenir7 from "../portfolio/uxui/nerede-ne-yenir/07.jpg";
import NNYenir8 from "../portfolio/uxui/nerede-ne-yenir/08.jpg";
import NNYenir9 from "../portfolio/uxui/nerede-ne-yenir/09.jpg";
/* Serviscell */
import Serviscell1 from "../portfolio/uxui/serviscell/01.jpg";
import Serviscell2 from "../portfolio/uxui/serviscell/02.jpg";
import Serviscell3 from "../portfolio/uxui/serviscell/03.jpg";
import Serviscell4 from "../portfolio/uxui/serviscell/04.jpg";
import Serviscell5 from "../portfolio/uxui/serviscell/05.jpg";
import Serviscell6 from "../portfolio/uxui/serviscell/06.jpg";
import Serviscell7 from "../portfolio/uxui/serviscell/07.jpg";
/* Garage */
import Garage1 from "../portfolio/uxui/work4.jpg";
/* Lo-fi Wireframe */
import Lofi1 from "../portfolio/uxui/lo-fi/01.jpg";
import Lofi2 from "../portfolio/uxui/lo-fi/02.jpg";
/* Blueprint */
import Blueprint1 from "../portfolio/uxui/blueprint/01.jpg";
import Blueprint2 from "../portfolio/uxui/blueprint/02.jpg";
import Blueprint3 from "../portfolio/uxui/blueprint/03.jpg";
/* Aembit */
import Aembit1 from "../portfolio/uxui/work7.jpg";
/* Lit */
import Lit1 from "../portfolio/uxui/work8.jpg";

function Uxui() {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="uxui starter-margin">
            <div class="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="portfolio__title">Selected Works</h3>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <p className="note confidential-note">For privacy reasons, I am unable to share visuals, names, and resources of specific projects.</p>
                    </div>
                    
                    <div className="tabset">
                        <ul>
                            <li className="tab__item">
                                <Link className="tab__item-link" to="/portfolio">UI Development</Link>
                            </li>
                            <li className="tab__item active">
                                <Link className="tab__item-link" to="/uxuidesign">UX/UI Design</Link>
                            </li>
                            <li className="tab__item">
                                <Link className="tab__item-link" to="/branding">Branding</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row work__grid">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Aembit1} alt="work" title="Aembit" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(1)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 1 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Aembit</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The Aembit Workload Identity and Access Management Platform project focuses on developing an advanced infrastructure-as-a-service (IaaS) web application. It aims to empower DevOps and Security teams in managing, enforcing, and auditing secure connections between federated workloads across platforms like AWS, Kubernetes services (GKE, AKS, EKS), Salesforce, and Microsoft Graph. The project includes the implementation of Aembit Cloud and Aembit Edge components, allowing for the creation and enforcement of access policies for program-to-program interactions. This involves intercepting client requests, authenticating clients, verifying authorization policies, and logging access events.<br>
                                            </br><br></br><strong>Period:</strong> 2 months
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Photoshop, Adobe Xd, Figma
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://aembit.io/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Aembit1} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Lit1} alt="work" title="Lives in Turkish" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(2)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 2 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Lives in Turkish</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The "Lives Collection" project at Boğaziçi University explores Turkish life-writing by investigating historical shifts in biography popularity and analyzing interactions between Ottoman-Turkish and Western life-writing forms. Utilizing data visualization techniques, such as story maps and network graphs, the project aims to reveal macro-contexts within the collected data.
                                            Continuously updating visualizations and exploring alternative methods, the project maintains a commitment to historical surveying, providing transparency and user interaction. Offering filtering options for biography subjects and publications, including region, field, century, publication type, and more, the project, powered by enhanced database software, ensures a dynamic and user-friendly platform. The ultimate goal is to unlock the potential of digital approaches in translation history.<br>
                                            </br><br></br><strong>Period:</strong> 1 month
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Photoshop, Adobe Xd
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://www.livesinturkish.com/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Lit1} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Uxui1} alt="work" title="Erasmusport" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(3)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 3 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Erasmusport</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The ErasmusPort project aims to provide support for Erasmus students and their universities by facilitating coordination, creating reminders, sending notifications, maintaining logs, securing connections, generating reports, and enhancing communication. The application, developed as a multi-component, cross-platform project, focuses on streamlining services for Erasmus participants. It specifically addresses the challenges of coordination, notification, logging, security, reporting, and communication, eliminating the need for hard-copy documentation and minimizing time wastage.<br>
                                            </br><br></br><strong>Period:</strong> 2 months
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Photoshop, Adobe Xd
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://erasmusport.com/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Erasmusport1} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmusport2} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmusport3} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmusport4} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmusport5} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Uxui2} alt="work" title="Nerede Ne Yenir?" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(4)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 4 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Nerede Ne Yenir?</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The project aims to develop a mobile application focused on nearby dining exploration. Users will be able to browse nearby cafeterias and restaurants using location services. The application will facilitate user interaction by allowing them to leave comments and ratings for visited places. Additionally, users will have the ability to contribute to the application by adding new dining establishments to the database. The project will incorporate a point-based rewards system, enabling users to accumulate points and redeem them for discounts. Overall, the objective is to create a platform that brings together dining places and users, enhancing the overall dining experience.<br>
                                            </br><br></br><strong>Period:</strong> 6 months
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Xd, Adobe Photoshop
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={NNYenir1} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir2} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir3} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir4} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir5} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir6} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir7} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir8} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={NNYenir9} alt="work" title="Nerede Ne Yenir" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Uxui3} alt="work" title="Serviscell" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(5)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 5 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Serviscell</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The application, optimized and deployed by school administrations or transportation companies, establishes a comprehensive student management system with a primary focus on safety and privacy. It includes real-time and retrospective tracking of students during their bus journeys, attendance recording, notifications in specific situations, and report generation. Utilizing technologies such as GSM, 3G, GPRS, GPS, SMS, NFC, camera, navigation, Bluetooth-CBS-Mobile, and Internet web-based communication, along with driver screens and sets, the application aims to facilitate information flow without the need for direct communication.<br>
                                            </br><br></br><strong>Period:</strong> 2 months
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Photoshop, Adobe Xd
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://serviscell.com.tr/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Serviscell1} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell2} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell3} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell4} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell5} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell6} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell7} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Uxui4} alt="work" title="Garage" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(6)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 6 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Garage</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The objective of this project is to develop a comprehensive mobile application for print management, allowing users to create memberships, submit print jobs, receive price quotes from printing houses, and track the progress of their orders. The key features include the implementation of a user membership system for secure account creation, a user-friendly interface for job submissions with options for file uploads and delivery preferences, seamless integration with printing houses for real-time price quotes, and a robust order tracking system. The technical requirements encompass cross-platform compatibility for iOS and Android, secure user authentication, API integration with printing houses, and a scalable architecture. The project timeline is expected to be within a specified timeframe, covering design, development, testing, and deployment. The budget will be finalized based on a detailed assessment, encompassing all development, testing, and implementation costs. Project deliverables include a fully functional, user-friendly mobile application meeting the specified requirements. This Scope of Project is subject to adjustments based on further discussions and refinements during the project planning phase.<br>
                                            </br><br></br><strong>Period:</strong> 3 months
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Photoshop, Adobe Xd
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Garage1} alt="work" title="Garage" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Uxui5} alt="work" title="Mobile Tracking App" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(7)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 7 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Mobile Tracking App</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Lofi1} alt="work" title="Mobile Tracking App" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lofi2} alt="work" title="Mobile Tracking App" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Uxui6} alt="work" title="Mobile Tracking App" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(8)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 8 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Mobile Tracking App</h3>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-apps work__modal-list-icon"></i> Adobe Illustrator
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Blueprint1} alt="work" title="Mobile Tracking App" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Blueprint2} alt="work" title="Mobile Tracking App" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Blueprint3} alt="work" title="Mobile Tracking App" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
        </div>
    );
}

export default Uxui;
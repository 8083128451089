import React, { useState } from 'react';
import "../base.css";
import "../assets/portfolio.css";
import { Link } from 'react-router-dom';
/* THUMBS */
import ninja from "../portfolio/frontend/work.jpg";
import Aembit1 from "../portfolio/frontend/work7.jpg";
import Lit1 from "../portfolio/frontend/work8.jpg";
import Serviscell1 from "../portfolio/frontend/work3.jpg";
import Erasmus1 from "../portfolio/frontend/work1.jpg";
import Barbaros1 from "../portfolio/frontend/work9.jpg";
import ninjacat from "../portfolio/frontend/ninjacat.png";
/* WORK GALLERY */
/* Aembit */
import Aembit15 from "../portfolio/frontend/aembit/00.jpg";
import Aembit2 from "../portfolio/frontend/aembit/01.jpg";
import Aembit3 from "../portfolio/frontend/aembit/02.jpg";
import Aembit4 from "../portfolio/frontend/aembit/03.jpg";
import Aembit5 from "../portfolio/frontend/aembit/04.jpg";
import Aembit6 from "../portfolio/frontend/aembit/05.jpg";
import Aembit7 from "../portfolio/frontend/aembit/06.jpg";
import Aembit8 from "../portfolio/frontend/aembit/07.jpg";
import Aembit9 from "../portfolio/frontend/aembit/08.jpg";
import Aembit10 from "../portfolio/frontend/aembit/09.jpg";
import Aembit11 from "../portfolio/frontend/aembit/10.jpg";
import Aembit12 from "../portfolio/frontend/aembit/11.jpg";
import Aembit13 from "../portfolio/frontend/aembit/12.jpg";
/* LivesinTurkish */
import Lit2 from "../portfolio/frontend/lit/01.jpg";
import Lit3 from "../portfolio/frontend/lit/02.jpg";
import Lit4 from "../portfolio/frontend/lit/03.jpg";
import Lit5 from "../portfolio/frontend/lit/04.jpg";
import Lit6 from "../portfolio/frontend/lit/05.jpg";
import Lit7 from "../portfolio/frontend/lit/06.jpg";
import Lit8 from "../portfolio/frontend/lit/07.jpg";
import Lit9 from "../portfolio/frontend/lit/08.jpg";
import Lit10 from "../portfolio/frontend/lit/09.jpg";
/* Serviscell */
import Serviscell2 from "../portfolio/frontend/serviscell/01.jpg";
import Serviscell3 from "../portfolio/frontend/serviscell/02.jpg";
import Serviscell4 from "../portfolio/frontend/serviscell/03.jpg";
import Serviscell5 from "../portfolio/frontend/serviscell/04.jpg";
import Serviscell6 from "../portfolio/frontend/serviscell/05.jpg";
import Serviscell7 from "../portfolio/frontend/serviscell/06.jpg";
import Serviscell8 from "../portfolio/frontend/serviscell/07.jpg";
/* Erasmusport */
import Erasmus2 from "../portfolio/frontend/erasmusport/01.jpg";
import Erasmus3 from "../portfolio/frontend/erasmusport/02.jpg";
import Erasmus4 from "../portfolio/frontend/erasmusport/03.jpg";
import Erasmus5 from "../portfolio/frontend/erasmusport/04.jpg";
/* Barbaros Yachting */
import Barbaros2 from "../portfolio/frontend/barbaros/01.jpg";
import Barbaros3 from "../portfolio/frontend/barbaros/02.jpg";
import Barbaros4 from "../portfolio/frontend/barbaros/03.jpg";
import Barbaros5 from "../portfolio/frontend/barbaros/04.jpg";
import Barbaros6 from "../portfolio/frontend/barbaros/05.jpg";
import Barbaros7 from "../portfolio/frontend/barbaros/06.jpg";
import Barbaros8 from "../portfolio/frontend/barbaros/07.jpg";


function Frontend() {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <div className="frontend starter-margin">
            <div class="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="portfolio__title">Selected Works</h3>
                    </div>

                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <p className="note confidential-note">For privacy reasons, I am unable to share visuals, names, and resources of specific projects.</p>
                    </div>
                    
                    <div className="tabset">
                        <ul>
                            <li className="tab__item active">
                                <Link className="tab__item-link" to="/portfolio">UI Development</Link>
                            </li>
                            <li className="tab__item">
                                <Link className="tab__item-link" to="/uxuidesign">UX/UI Design</Link>
                            </li>
                            <li className="tab__item">
                                <Link className="tab__item-link" to="/branding">Branding</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="row work__grid">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={Aembit1} alt="work" title="Aembit" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(1)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 1 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Aembit</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The Aembit Workload Identity and Access Management Platform project focuses on developing an advanced infrastructure-as-a-service (IaaS) web application. It aims to empower DevOps and Security teams in managing, enforcing, and auditing secure connections between federated workloads across platforms like AWS, Kubernetes services (GKE, AKS, EKS), Salesforce, and Microsoft Graph. The project includes the implementation of Aembit Cloud and Aembit Edge components, allowing for the creation and enforcement of access policies for program-to-program interactions. This involves intercepting client requests, authenticating clients, verifying authorization policies, and logging access events.<br>
                                            </br><br></br><strong>Period:</strong> 2022 - 2024
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Angular.js, React.js, Next.js, Material, Node.js, Adobe Photoshop/Xd, Figma, Design Principles, Design Thinking, Branding, UI/UX Design, Adobe Illustrator, CI/CD, Git, Code Quality, Agile/Scrum, Jira 
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://aembit.io/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Aembit2} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit15} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit3} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit4} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit5} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit6} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit7} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit8} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit9} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit10} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit11} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit12} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Aembit13} alt="work" title="Aembit" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="ERP" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(2)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 2 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Enterprise Resource Planning App</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> This ERP project aims to empower both employees and the HR department with a suite of user-friendly tools. For employees, the system facilitates PTO or absence requests, tracking, viewing company calendars, profile customization with skillset creation, Jira integration for task monitoring, access to performance evaluation forms and documents, retrieval of company procedures, and scheduling one-to-one meetings with the HR department.
                                            On the HR side, the objectives include managing and viewing employee PTOs or absence situations, organizing the company calendar, streamlining project assignments based on employee skillsets, accessing performance evaluation forms, and reports, disseminating company procedures to employees, simplifying the onboarding process, and accommodating one-to-one meeting requests from employees. The project aims to enhance overall efficiency and communication within the organization.<br>
                                            </br><br></br><strong>Period:</strong> 2021 - 2024
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, React.js, Tailwindcss, Material, Node.js, Adobe Photoshop, Figma, Design Principles, Design Thinking, Branding, UI/UX Design, Adobe Illustrator, Docker, CI/CD, Git, Code Quality, Agile, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="Enhanced Healthcare Management System" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(3)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 3 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Enhanced Healthcare Management System</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The project includes a comprehensive enhancement strategy covering document management, streamlined personnel record-keeping, and optimized operational insights. This involves updating the database, simplifying the interface for improved document storage, integrating Azure Storage Container, and enhancing the interface for personnel record-keeping in operational regions.
                                            A pivotal development is the creation of an interactive map for extensive access to region-specific details. This, along with an overhauled reporting system, aims to optimize operational insights. Additionally, a dynamic event calendar, utilizing the Metronic UI framework, enhances communication and coordination among personnel. The marketing site will be updated to showcase the latest enhancements, ensuring stakeholders are well-informed.<br>
                                            </br><br></br><strong>Period:</strong> 2022 - 2023
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, React.js, Metronic, Node.js, Design Thinking, CI/CD, Git, Agile, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="E-learning Web App" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(4)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 4 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">E-learning Web App</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The project aimed to digitize a school's physical training sessions and procedures, resulting in the development of a comprehensive web application. This application, meticulously designed for optimal functionality on phones and tablets, catered to various stakeholders, including teachers, school administrators, students, and parents.
                                            In addition to the web application, a dedicated marketing website was created to promote the platform and provide support. Unique management interfaces were tailored for student parents and school staff/teachers. Emphasizing visual aesthetics for both the application and marketing website, online classes are seamlessly integrated through API from an external platform to prevent potential performance issues.
                                            The application facilitated the tracking of lessons, assignments, and exams, offering features for monitoring student attendance, grades, and teacher feedback. To maintain direct communication between students and teachers, various channels, including one-on-one online meeting appointments and a question-and-answer section, were implemented, preserving personalized interaction within the online learning environment.<br>
                                            </br><br></br><strong>Period:</strong> 2020 - 2023
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Adobe Illustrator, Git, Scrum, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="Integrated Healthcare Data Management" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(5)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 5 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Integrated Healthcare Data Management</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> A web-based care management portal has been designed to tackle care gaps for the client through the creation of a Software-as-a-Service. This portal provides access to health data collected from health institutions for both patients and healthcare professionals, fostering strong communication between physicians and other medical industry partners like pharmacies, radiology centers, and laboratories within the same portal.
                                            The portal offers a range of functionalities, including the ability to view and manage health information and medical backgrounds, access radiological examinations and laboratory test results, review past visit notes and medication follow-up details, as well as facilitate E-prescribing of medicines. Furthermore, users can electronically request and track E-lab tests and radiology examinations through the portal, providing a comprehensive solution to bridge care gaps.<br>
                                            </br><br></br><strong>Period:</strong> 2021 - 2022
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Vus.js, Tailwindcss, Material, Design Thinking, CI/CD, Git, Code Quality, Agile, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="Order Management System" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(6)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 6 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Order Management System</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> This project involves crafting an employee-facing mobile app for an American outdoor sporting goods retailer operating across 25 states. The app aims to seamlessly integrate existing software applications (Hybris for eCommerce, Retail.NET for in-store sales and inventory, and UPS Campus Ship for shipping labels) into a unified omnichannel experience. The mobile app, designed for Zebra handheld devices (model TC51), will enhance workflow efficiency, providing clients with a cohesive and user-friendly interface. Utilizing the Microsoft .NET technology stack, the web application will leverage ASP.NET Core MVC, ASP.NET Core Web API, Entity Framework Core, and Microsoft SQL Server to deliver a streamlined and robust solution.<br>
                                            </br><br></br><strong>Period:</strong> 2020 - 2021
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Design Thinking, Git, Agile, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="Unified Clinical Logistics Management System" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(7)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 7 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Unified Clinical Logistics Management System</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The project focuses on the comprehensive optimization of a vital Clinical Logistics Management System. This multifaceted endeavor encompasses the refinement of both customer-centric and internal operational applications. The primary goal is to enhance the efficiency and functionality of these critical components, streamlining processes for a global provider specializing in medical equipment rentals for clinical trials. The endeavor involves addressing challenges related to UI framework integration, resolving technical complexities within the internal operations application and API, and establishing clearer decision-making processes. The ultimate aim is to bolster the client's capabilities in managing clinical trial logistics with improved clarity, responsiveness, and overall operational efficacy.<br>
                                            </br><br></br><strong>Period:</strong> 2020 - 2021
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, React.js, Tailwindcss, Material, Node.js, Design Thinking, CI/CD, Git, Agile, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Lit1} alt="work" title="Lives in Turkish" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(8)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 8 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Lives in Turkish</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The "Lives Collection" project at Boğaziçi University explores Turkish life-writing by investigating historical shifts in biography popularity and analyzing interactions between Ottoman-Turkish and Western life-writing forms. Utilizing data visualization techniques, such as story maps and network graphs, the project aims to reveal macro-contexts within the collected data.
                                            Continuously updating visualizations and exploring alternative methods, the project maintains a commitment to historical surveying, providing transparency and user interaction. Offering filtering options for biography subjects and publications, including region, field, century, publication type, and more, the project, powered by enhanced database software, ensures a dynamic and user-friendly platform. The ultimate goal is to unlock the potential of digital approaches in translation history.<br>
                                            </br><br></br><strong>Period:</strong> 2019 - 2020
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, React.js, Material, Node.js, Adobe Photoshop, Design Principles, Design Thinking, Branding, Adobe Illustrator, Git, Scrum, Jira 
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://www.livesinturkish.com/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Lit2} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit3} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit4} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit5} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit6} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit7} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit8} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit9} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Lit10} alt="work" title="Lives in Turkish" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                    <img src={ninja} alt="work" title="An Account-Based Marketing System" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(9)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 9 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">An Account-Based Marketing System</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> This project involves developing a sophisticated web application tailored for the marketing team, with a primary focus on account-based marketing principles. The application features a comprehensive social graph mapping companies, board members, employees, and relevant contacts for targeted marketing strategies. Emphasis has been placed on crafting an intuitive and user-friendly interface to enhance the user experience. The project unfolds in two phases: an initial six-week development phase, covering database management and system logic, followed by a refinement stage focused on frontend improvements. The goal is to deliver a robust and efficient web application empowering the marketing team in executing strategies effectively.<br>
                                            </br><br></br><strong>Period:</strong> 2019
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, React.js, Bootstrap, Node.js, Adobe Photoshop, Design Thinking, CI/CD, Git, Code Quality, Agile, Jira 
                                            </li>
                                            <li>
                                                <a aria-disabled="true" className="work__modal-list-link disabled-link" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <p className="work__modal-description">The visuals of this project are disguised to maintain confidentiality. Imagine it like a ninja cat guarding the project – you might not see it, but the impact is always felt!</p>
                                            <div>
                                                <img src={ninjacat} alt="work" title="Sorry." className="work__gallery-img ninjacat" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Serviscell1} alt="work" title="Serviscell" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(10)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 10 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Serviscell</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The application, optimized and deployed by school administrations or transportation companies, establishes a comprehensive student management system with a primary focus on safety and privacy. It includes real-time and retrospective tracking of students during their bus journeys, attendance recording, notifications in specific situations, and report generation. Utilizing technologies such as GSM, 3G, GPRS, GPS, SMS, NFC, camera, navigation, Bluetooth-CBS-Mobile, and Internet web-based communication, along with driver screens and sets, the application aims to facilitate information flow without the need for direct communication.<br>
                                            </br><br></br><strong>Period:</strong> 2016 - 2018
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Adobe Xd, Design Principles, Design Thinking, UI/UX Design, Git, Kanban 
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://serviscell.com.tr/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Serviscell1} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell2} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell3} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell4} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell5} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell6} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell7} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Serviscell8} alt="work" title="Serviscell" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Erasmus1} alt="work" title="Erasmusport" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(11)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 11 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Erasmusport</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The ErasmusPort project aims to provide support for Erasmus students and their universities by facilitating coordination, creating reminders, sending notifications, maintaining logs, securing connections, generating reports, and enhancing communication. The application, developed as a multi-component, cross-platform project, focuses on streamlining services for Erasmus participants. It specifically addresses the challenges of coordination, notification, logging, security, reporting, and communication, eliminating the need for hard-copy documentation and minimizing time wastage.<br>
                                            </br><br></br><strong>Period:</strong> 2016
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Adobe Photoshop/Xd, Design Principles, Design Thinking, UI Design, Kanban 
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://erasmusport.com/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Erasmus1} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmus2} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmus3} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmus4} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Erasmus5} alt="work" title="Erasmusport" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="work__box">
                                <div>
                                <img src={Barbaros1} alt="work" title="Barbaros Yachting" className="work__img" />
                                </div>

                                <div className="tool__group">
                                    <span className="detail__button" onClick={() => toggleTab(12)}>
                                        Detail
                                    </span>
                                </div>

                                <div className={toggleState === 12 ? "work__modal active-modal" : "work__modal"}>
                                    <div className="work__modal-content">
                                        <i onClick={() => toggleTab(0)} className="uil uil-times work__modal-close"></i>

                                        <h3 className="work__modal-title">Barbaros Yachting</h3>
                                        <p className="work__modal-description">
                                            <strong>Scope of Project:</strong> The project involves the development of a comprehensive marketing website for a major yacht tourism company. The website serves as a platform for the company to introduce itself, showcase its fleet of yachts, present products and tours, and highlight services. Additionally, the website features an integrated online reservation application and a Chat Bot to efficiently address user queries. The site is designed to be multilingual, publishing the same content in 7 different languages based on the user's location. The primary objectives of the site are to establish credibility, enhance visibility, and reach a broader audience.<br>
                                            </br><br></br><strong>Period:</strong> 2014 - 2016
                                        </p>
                                        <hr></hr>

                                        <ul className="work__modal-list">
                                            <li className="border-right__dotted border-none-sm">
                                                <i class="uil uil-arrow work__modal-list-icon"></i> HTML/CSS/Js, Bootstrap, Adobe Photoshop 
                                            </li>
                                            <li>
                                                <a className="work__modal-list-link" href="https://www.barbarosyachting.com.tr/" rel="noreferrer" target="_blank"><i class="uil uil-link work__modal-list-icon"></i> Demo/Source</a>
                                            </li>
                                        </ul>
                                        <hr></hr>

                                        <div className="work__gallery">
                                            <div>
                                                <img src={Barbaros2} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Barbaros3} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Barbaros4} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Barbaros5} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Barbaros6} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Barbaros7} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                            <div>
                                                <img src={Barbaros8} alt="work" title="Barbaros Yachting" className="work__gallery-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer"></div>
        </div>
    );
}

export default Frontend;